
































import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'AsyncCharacterisitic',
  setup() {
    const resourceList = [
      {
        name: '机器人特色校',
        src: 'https://zyb-zhike-file.cdnjtzy.com/mis/849a8fa0-966a-4fe1-b23c-db5613fc516d.png',
        desc: '涵盖机械结构与传动、动力与能量、机器人基础知识和图形化编程等板块，培养动手能力和逻辑思维。'
      },
      {
        name: '木工坊特色校',
        src: 'https://zyb-zhike-file.cdnjtzy.com/mis/bf8eb050-19ef-4ea4-8e26-ac5519b22635.png',
        desc: '以团队项目的方式开展木艺课程，让学生学会使用木艺制作各种工具，学会看图纸，设计图纸。',
        extra: ''
      },
      {
        name: '食育坊特色校',
        src: 'https://zyb-zhike-file.cdnjtzy.com/mis/a9fce4ed-6a45-49dc-9351-570c2813112f.png',
        desc: '通过饮食观念、饮食安全等知识教育，结合烹饪、栽种等劳动实践教育，让学生理解和传承食文化。',
        extra: ''
      },
      {
        name: '陶工坊特色校',
        src: 'https://zyb-zhike-file.cdnjtzy.com/mis/bac7e05d-0f2b-4322-ae58-7e109fa4980a.png',
        desc: '通过优雅的环境、安全齐备的设备、体系先进的课程，学习陶艺的制作过程，感知传统文化的魅力。',
        extra: ''
      },
      {
        name: '织染工坊特色校',
        src: 'https://zyb-zhike-file.cdnjtzy.com/mis/ca21cb59-ca5d-419a-9538-2869802236d8.png',
        desc: '从纺棉线，织粗布开始，对编织及刺绣艺术进行全面了解，让学生体验古代劳动人民的辛勤与智慧。',
        extra: ''
      },
      {
        name: 'AI-STEAM特色校',
        src: 'https://zyb-zhike-file.cdnjtzy.com/mis/2621b9b8-eba6-4ba5-a9a8-62b4bfe67068.png',
        desc: '以人工智能编程车为核心，采取PBL的学习方式，将STEAM的教学理念融合到建构和编程学习中。'
      }
    ]

    const currentIndex = ref(0)
    const handleMouseEnter = (index: number) => {
      currentIndex.value = index
      const dom: any = document.getElementsByClassName(`insert-back${index}`)[0]
        .children[2]
      dom.style.display = 'none'
      dom.style.transition = 'opacity 1s'
      dom.style.opacity = '0.5'
      setTimeout(() => {
        dom.style.display = 'flex'
        dom.style.opacity = '1'
      }, 500)
    }

    const handleMouseLeave = (index: number) => {
      currentIndex.value = index
    }

    return {
      resourceList,
      currentIndex,
      handleMouseEnter,
      handleMouseLeave
    }
  }
})
