import { render, staticRenderFns } from "./characteristic.vue?vue&type=template&id=1a7daee5&scoped=true&"
import script from "./characteristic.vue?vue&type=script&lang=ts&"
export * from "./characteristic.vue?vue&type=script&lang=ts&"
import style0 from "./characteristic.vue?vue&type=style&index=0&id=1a7daee5&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a7daee5",
  null
  
)

export default component.exports